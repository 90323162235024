import { connect } from "react-redux";
import CustomReportNonwinterForm from "./CustomReportNonwinterForm";
import { saveCustomReportFilterValues } from "./ReportSettingsDuck";
import { createSelector } from 'reselect';

const EMPTY_INITIAL_VALUES = {
  threat: {
    maxPop: 0,
    totalPrecipIN: 0.0,
    thunderStorm: false, 
    flood: null, 
    fog: false, 
    severe: null, 
    damagingWind: null, 
    torn: null, 
    hail: null
  },
  tropical: {isInErrorCone: null, distanceFromCone: null, forecastStormCat: null, windSpeedMaxMPH: 0, windGustMPH: 0, excessiveRainfallOutlook: null},
  name: "",
  id: undefined,
};

const selectReportSettings = state => state.reportSettings;

const getCustomReportSettings = createSelector(
  selectReportSettings,
  reportSettings => {
    const customReport =
      reportSettings.customReports && reportSettings.customReports.length
        ? reportSettings.customReports[0]
        : {};
    const {name, id, valuesNonwinter: values} = customReport;
    let initialValues;
    if(!id) {
      initialValues = { ...EMPTY_INITIAL_VALUES }
    } else {
      initialValues = {
        name, 
        id, 
        threat:{ 
          maxPop: 0, 
          totalPrecipIN: 0.0,
          ...values?.threat,
        },
        tropical: values?.tropical ?? {},
      }
    }
    return initialValues;
  }
);

const mapStateToProps = (state, ownProps) => ({
  customReportInitialValues: getCustomReportSettings(state),
  emptyInitialValues: {...EMPTY_INITIAL_VALUES},
  isFetching: state.reportSettings.isFetching,
  apiError: state.reportSettings.apiError,
  isSavedCustomReportsSuccess: state.reportSettings.customReportSaveSuccess,
  companyId: ownProps.companyId,
});

const mapDispatchToProps = dispatch => ({
  saveCustomReportFilterValues: (companyId, values, name, id) => dispatch(saveCustomReportFilterValues(companyId, null/*winter*/, values, name, id))
});
// const mapDispatchToProps = dispatch => ({
//   saveCustomReportFilterValues: (companyId, values, name, id) => console.log("submit", values, name, id)
// });

const CustomReportFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReportNonwinterForm);
export default CustomReportFormContainer;
