import log from "loglevel";
import immutable from "object-path-immutable";
import { usStateMap } from "common/helpers/locationAbbrev";
import { arr2obj } from "common/helpers/datahelpers";

import {
  SITE_ACTIVATE_SUCCESS,
  BULK_SITES_ACTIVATE_SUCCESS,
} from "Admin/SiteAdmin/SiteAdminDuck";
import { SITEDELETE_SUCCESS } from "Admin/SiteEdit/SiteEditDuck";
import { SITEADD_SUCCESS } from "Admin/SiteAdd/SiteAddDuck";
import { SITEEDIT_SUCCESS } from "Admin/SiteEdit/SiteEditDuck";
import {
  COMPANYDATA_SUBMIT,
  COMPANYDATA_SUCCESS,
  COMPANYDATA_FAILURE
} from "./CompanyDuck";

export const DATA_SUBMIT = "DATA_SUBMIT";
export const DATA_SUCCESS = "DATA_SUCCESS";
export const DATA_FAILURE = "DATA_FAILURE";
export const SITE_DATA_SUBMIT = "SITE_DATA_SUBMIT";
export const SITE_DATA_SUCCESS = "SITE_DATA_SUCCESS";
export const SITE_DATA_FAILURE = "SITE_DATA_FAILURE";
export const SITE_HOURLY_FORECAST_SUBMIT = "SITE_HOURLY_FORECAST_SUBMIT";
export const SITE_HOURLY_FORECAST_SUCCESS = "SITE_HOURLY_FORECAST_SUCCESS";
export const SITE_HOURLY_FORECAST_FAILURE = "SITE_HOURLY_FORECAST_FAILURE";
export const SITE_OBSERVATIONS_SUBMIT = "SITE_OBSERVATIONS_SUBMIT";
export const SITE_OBSERVATIONS_SUCCESS = "SITE_OBSERVATIONS_SUCCESS";
export const SITE_OBSERVATIONS_FAILURE = "SITE_OBSERVATIONS_FAILURE";
export const SITES_SUBMIT = "SITES_SUBMIT";
export const SITES_SUCCESS = "SITES_SUCCESS";
export const SITES_FAILURE = "SITES_FAILURE";

export function fetchSites(companyId) {
  return {
    type: DATA_SUBMIT,
    companyId
  };
}

export function fetchSitesBasicInformation(companyId) {
  return {
    type: SITE_DATA_SUBMIT,
    companyId
  };
}

export const loadCompanySites = (companyId) => {
  return {
    type: SITES_SUBMIT,
    companyId,
  }
}

export function fetchSiteHourlyForecasts(authToken, siteId) {
  return {
    type: SITE_HOURLY_FORECAST_SUBMIT,
    payload: {
      authToken,
      siteId
    }
  };
}

export function fetchSiteObservations(authToken, siteId) {
  return {
    type: SITE_OBSERVATIONS_SUBMIT,
    payload: {
      authToken,
      siteId
    }
  };
}

const safePush = (ary, obj) => {
  if (!ary) ary = [];
  ary.push(obj);
  return ary;
};

const putRelatedSummaryToEvent = (event, summaryForecasts) => {
  if (!summaryForecasts || !summaryForecasts.length) {
    return;
  }

  event.days = getEventDays(event);

  event.startDay = -1;
  for (let i = 0; i < event.days.length; i++) {
    if (event.days[i]) {
      event.startDay = i;
      break;
    }
  }

  if (event.startDay >= 0 && event.startDay < summaryForecasts.length) {
    event.relatedSummary = summaryForecasts[event.startDay];
  }
};

const getHourDiffBetweenDates = (firstDate, secDate) => {
  return Math.abs(firstDate - secDate) / 36e5;
};

const getDayIndexForHourDiff = diff => {
  if (diff < 24) {
    return 0;
  } else if (diff < 48) {
    return 1;
  } else if (diff < 72) {
    return 2;
  } else {
    return 3;
  }
};

const getEventDays = event => {
  const days = [false, false, false, false];

  const startDate = new Date(event.startDate);
  const endDate = new Date(event.endDate);
  const currDate = new Date();

  const startDiffH = getHourDiffBetweenDates(startDate, currDate);
  const endDiffH = getHourDiffBetweenDates(endDate, currDate);

  const startIndex = getDayIndexForHourDiff(startDiffH);
  const endIndex = getDayIndexForHourDiff(endDiffH);

  for (var i = startIndex; i <= endIndex; i++) {
    days[i] = true;
  }

  return days;
};

// convert sites from array to associative map and connect them to threats,
// events and forecasts
const connectSites = ({
  sites = [],
  forecasts = [],
  events = [],
  threats = [],
  advisories = [],
  siteConvectiveOutlooks = [],
  convectiveOutlooks = []
}) => {
  const newsites = {};
  sites.forEach(site => {
    newsites[site.id] = {
      ...site,
      events: [],
      threats: [],
      advisories: [],
      eventIds: [],
      threatIds: [],
      forecastIds: [],
      summaryForecastIds: [],
      advisoryIds: [],
      relatedSummary: null,
      convectiveOutlookIds: []
    };
  });

  const newforecasts = {};
  forecasts.forEach(forecast => {
    newforecasts[forecast.id] = forecast;
    const relatedSite = newsites[forecast.siteId];
    if (relatedSite) {
      if (forecast.timeSpanH !== 1) {
        relatedSite.summaryForecastIds = safePush(
          relatedSite.summaryForecastIds,
          forecast.id
        );
        relatedSite.summaryForecasts = safePush(
          relatedSite.summaryForecasts,
          forecast
        );
      } else {
        relatedSite.forecastIds = safePush(
          relatedSite.forecastIds,
          forecast.id
        );
      }
    } else {
      log.warn("Unknown site ID found in forecast:", forecast.siteId);
    }
  });

  const newevents = {};
  events.forEach(event => {
    newevents[event.id] = event;
    const relatedSite = newsites[event.siteId];
    if (relatedSite) {
      relatedSite.eventIds = safePush(relatedSite.eventIds, event.id);
      relatedSite.events = safePush(relatedSite.events, event);
    } else {
      log.warn("Unknown site ID found in events:", event.siteId);
    }
  });

  threats.forEach(threat => {
    const relatedSite = newsites[threat.siteId];
    if (relatedSite) {
      relatedSite.threatIds = safePush(relatedSite.threatIds, threat.id);
      relatedSite.threats = safePush(relatedSite.threats, threat);
    } else {
      log.warn("Unknown site ID found in threat:", threat.siteId);
    }
  });

  advisories.forEach(advisory => {
    const relatedSite = newsites[advisory.siteId];
    if (relatedSite) {
      relatedSite.advisoryIds = safePush(relatedSite.advisoryIds, advisory.id);
      relatedSite.advisories = safePush(relatedSite.advisories, advisory);
    } else {
      log.warn("Unknown site ID found in advisory:", advisory.siteId);
    }
  });

  // augment Site objects with convectiveOutlooks
  convectiveOutlooks.forEach(co => {
    co.siteIds.forEach(sid => {
      const relatedSite = newsites[sid];
      relatedSite.convectiveOutlookIds = safePush(relatedSite.convectiveOutlookIds, co.id);
      relatedSite.convectiveOutlooks = safePush(relatedSite.convectiveOutlooks, co);
    });
  });

  for (let siteId in newsites) {
    const site = newsites[siteId];
    site.events.forEach(event => {
      putRelatedSummaryToEvent(event, site.summaryForecasts);
    });
  }

  return newsites;
};

// TODO: refactor/rename: it's not just for hourly forecasts anymore
const addHourlyForecastIdsToSite = ({ sites, siteId, siteHourlyForecasts, sitePeriodForecasts }) => {
  const relatedSite = sites[siteId];
  if (!relatedSite) {
    log.warn("Unknown site ID is used for hourly forecast fetch:", siteId);
    return sites;
  } else {
    const newSites = {};
    for (let id in sites) {
      newSites[id] = sites[id];
    }
    const newRelatedSite = newSites[siteId];
    newRelatedSite.forecastIds = siteHourlyForecasts.map(forecast => forecast.id);
    newRelatedSite.summaryForecastIds = sitePeriodForecasts.map(forecast => forecast.id);
    newRelatedSite.summaryForecasts = sitePeriodForecasts.map(forecast => forecast);

    return newSites;
  }
};

const addHourlyForecasts = (forecasts, hourlyForecasts=[]) => {
  const newForecasts = {};
  for (let id in forecasts) {
    newForecasts[id] = forecasts[id];
  }

  hourlyForecasts.forEach(forecast => {
    newForecasts[forecast.id] = forecast;
  });

  return newForecasts;
};

// convert events from array to associative map
export function events2obj({ events = [] }) {
  const newevents = arr2obj(events);
  return newevents;
}

// convert threats from array to associative map
export function threats2obj({ threats = [], events = {} }) {
  const newthreats = arr2obj(threats);
  return newthreats;
}

// extracts all federal states (abbreviated) and their cities to create a
// mapping from full state name to array of city names
const extractStatesAndCities = ({ sites = [] }) => {
  const stateMap = {};
  sites.forEach(s => {
    const fullStateName = usStateMap[s.state.toUpperCase().trim()];
    // collect cities first in Set to avoid duplicates
    let oldmapping = stateMap[fullStateName]
      ? stateMap[fullStateName]
      : new Set();
    oldmapping = oldmapping.add(s.city.trim());
    stateMap[fullStateName] = oldmapping;
  });
  // change Set to Array
  Object.keys(stateMap).forEach(k => {
    const ary = Array.from(stateMap[k]);
    ary.sort();
    stateMap[k] = ary;
  });
  const rv = Object.keys(stateMap)
    .sort()
    .reduce((r, k) => {
      r[k] = stateMap[k];
      return r;
    }, {});
  return rv;
};

// reducers for sites, threats and events
const initialData = {
  isFetching: false,
  message: "",
  isDataLoaded: false,
  compCalls: 0 //semaphore for number of calls to load company data
};
export function dataLoadingStatus(state = initialData, action) {
  switch (action.type) {
    case DATA_SUBMIT:
      return {
        ...state,
        isFetching: true,
        message: action.message,
        isDataLoaded: false
      };
    case DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        isDataLoaded: true
      };
    case DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        isDataLoaded: false
      };

    case SITE_DATA_SUCCESS:
      return {
        ...state,
        isSiteBasicDataLoaded: true
      };
    case SITE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        isSiteBasicDataLoaded: false
      };


    case COMPANYDATA_SUBMIT:
    case "COMPANY_PERSONDATA_SUBMIT":
    case "SITES_SUBMIT":
    case "USER_REPORTSUBS_BY_COMPANY_SUBMIT":
    case "SUBSCRIPTIONDATA_SUBMIT":
    case "COMPANYINFOS_SUBMIT":
      return {
        ...state,
        isFetchingCompanies: true,
        compCalls: state.compCalls + 1
      };

    case COMPANYDATA_SUCCESS:
    case "COMPANY_PERSONDATA_SUCCESS":
    case "SITES_SUCCESS":
    case "USER_REPORTSUBS_BY_COMPANY_SUCCESS":
    case "SUBSCRIPTIONDATA_SUCCESS":
    case "COMPANYSUBSCRIPTION_SUCCESS":
    case "COMPANYINFOS_SUCCESS":
      return {
        ...state,
        isFetchingCompanies: state.compCalls > 1,
        compCalls: state.compCalls - 1
      };

    case COMPANYDATA_FAILURE:
    case "COMPANY_PERSONDATA_FAILURE":
    case "SITES_FAILURE":
    case "USER_REPORTSUBS_BY_COMPANY_FAILURE":
    case "SUBSCRIPTIONDATA_FAILURE":
    case "COMPANYSUBSCRIPTION_NOTFOUND":
    case "COMPANYINFOS_FAILURE":
      return {
        ...state,
        isFetchingCompanies: state.compCalls > 1,
        compCalls: state.compCalls - 1
      };

    default:
      return state;
  }
}

// duplicating backend action: setting site inactive will set synced to false
const updateActiveState = (site, { active }) => ({
  ...site,
  active,
  synced: active ? site.synced : false
});

// duplicating backend action to add single or multiple sites
// backend doesn't return active/synced flags, so must be set // here explicitly
export const addSiteToSiteMap = (map = {}, site) =>
  immutable.set(map, [site.id], {
    ...site,
    active: true,
    synced: false
  });

export const addSiteArrayToSiteMap = (map = {}, siteArr) =>
  siteArr.reduce((acc, site) => addSiteToSiteMap(acc, site), map);

// when changing Site data, it might be re-geocoded, depending
// on the changed fields; also we must keep any related data from old object
export const updateSite = (oldSiteObj, { siteObj, needsSync }) =>
  siteObj && oldSiteObj
    ? {
        ...oldSiteObj,
        ...siteObj,
        synced: !needsSync
      }
    : oldSiteObj;

export function sites(state = {}, action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return connectSites(action.payload);

    case SITE_ACTIVATE_SUCCESS:
      return state[action.siteId]
        ? {
            ...state,
            [action.siteId]: updateActiveState(state[action.siteId], action)
          }
        : state;

    case SITEDELETE_SUCCESS:
      // optimistically delete site from frontend data after backend signals success
      let { [action.siteId]: deleted, ...restSites } = state;
      return restSites;

    case SITE_HOURLY_FORECAST_SUCCESS:
      return addHourlyForecastIdsToSite({ sites: state, ...action.payload });

    case SITEADD_SUCCESS:
      return action.siteObj
        ? addSiteToSiteMap(state, action.siteObj)
        : action.sites && action.sites.length !== 0
        ? addSiteArrayToSiteMap(state, action.sites)
        : state;

    case SITEEDIT_SUCCESS:
      return {
        ...state,
        [action.siteObj.id]: updateSite(state[action.siteObj.id], action)
      };

    default:
      return state;
  }
}

export function threats(state = {}, action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return threats2obj(action.payload);

    default:
      return state;
  }
}

export function events(state = {}, action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return events2obj(action.payload);

    default:
      return state;
  }
}

export function statesandcities(state = {}, action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return extractStatesAndCities(action.payload);

    default:
      return state;
  }
}

export function notifications(state = {}, action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return arr2obj(action.payload.notifications);

    default:
      return state;
  }
}

export function forecasts(state = {}, action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return arr2obj(action.payload.forecasts);
    case SITE_HOURLY_FORECAST_SUCCESS:
      return addHourlyForecasts(state, action.payload?.siteHourlyForecasts);
    default:
      return state;
  }
}

export function observations(state = {}, action) {
  switch (action.type) {
    case SITE_OBSERVATIONS_SUCCESS:
      return arr2obj(action.payload.observations);

    default:
      return state;
  }
}

export function advisoryTypes(state = [], action) {
  switch (action.type) {
    case DATA_SUCCESS:
      return action.payload.advisoryTypes || null;

    default:
      return state;
  }
}

export function sitesBasicInformation(state = { winter: "n/a", nonwinter: "n/a"}, action) {
  switch (action.type) {
    case SITE_DATA_SUCCESS:
      return action.payload 
        ? {
          siteCount: action.payload.siteCount,
          winter: action.payload.winterSiteCount,
          nonwinter: action.payload.nonwinterSiteCount
        }
        : null ;

    default:
      return state;
  }
}

const deletedSite = (data, siteId) => {
  const newData = { ...data };
  Object.keys(data).forEach(cid => {
    let { [siteId]: deleted, ...restSites } = data[cid];
    newData[cid] = restSites;
  });
  return newData;
};

const updatedActiveState = (data, { siteId, active }) => {
  const newData = { ...data };
  Object.keys(data).forEach(cid => {
    newData[cid] = { ...data[cid] };
    Object.keys(newData[cid]).forEach(sid => {
      newData[cid][sid] = { ...data[cid][sid] };
      if (sid === siteId) {
        newData[cid][sid].active = active;
        newData[cid][sid].synced = active ? data[cid][sid].synced : false;
      }
    });
  });
  return newData;
};

const updatedBulkActiveState = (data, { companyId, active, siteIds }) => {
  siteIds = siteIds || []
  const newData = { ...data };
  Object.keys(data).forEach(cid => {
    newData[cid] = { ...data[cid] };
    Object.keys(newData[cid]).forEach(sid => {
      newData[cid][sid] = { ...data[cid][sid] };
      if (siteIds.includes(sid)) {
        newData[cid][sid].active = active;
        newData[cid][sid].synced = active ? data[cid][sid].synced : false;
      }
    });
  });

  return newData;
};

// only the /Sites data, without related (forecasts, etc)
export function companySites(state = {}, action) {
  switch (action.type) {
    case SITES_SUBMIT:
      return {
        ...state,
        isFetching: true
      };

    case SITES_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.companyId]: arr2obj(action.sites) },
        isFetching: false
      };

    case SITES_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message
      };

    case SITEDELETE_SUCCESS:
      // optimistically delete site from frontend data after backend signals success
      return {
        ...state,
        data: deletedSite(state.data, action.siteId)
      };

    case SITE_ACTIVATE_SUCCESS:
      return {
        ...state,
        data: updatedActiveState(state.data, action)
      };

    case BULK_SITES_ACTIVATE_SUCCESS:
      return {
        ...state,
        data: updatedBulkActiveState(state.data, action)
      };

    case SITEADD_SUCCESS:
      const { siteObj, sites } = action;
      const companyId =
        sites && sites.length !== 0 ? sites[0].companyId : undefined;
      return {
        ...state,
        data: siteObj
          ? // single site
            {
              ...state.data,
              [siteObj.companyId]: addSiteToSiteMap(
                state.data[siteObj.companyId],
                siteObj
              )
            }
          : // array of sites
          sites && sites.length !== 0
          ? {
              ...state.data,
              [companyId]: addSiteArrayToSiteMap(state.data[companyId], sites)
            }
          : state
      };

    case SITEEDIT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.siteObj.companyId]: {
            ...state.data[action.siteObj.companyId],
            [action.siteObj.id]: updateSite(
              state.data[action.siteObj.companyId][action.siteObj.id],
              action
            )
          }
        }
      };

    default:
      return state;
  }
}

export default sites;
